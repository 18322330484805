@use '../abstracts';

.backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // find var for backdrop
    background-color: hsla(0, 0%, 50%, 0.5);
    backdrop-filter: blur(4px);
    z-index: 9999;

    --background-color: #{abstracts.$dark-background-color};
    --text-color: #{abstracts.$dark-text-color};
    --accent-color: #{abstracts.$dark-accent-color};

    &.light {
        --background-color: #{abstracts.$light-background-color};
        --text-color: #{abstracts.$light-text-color};
        --accent-color: #{abstracts.$light-accent-color};
    }
}

// TODO: format inner modal positions (looks weird rn)

.modal {
    width: clamp(50vw, 50rem, 90vw);;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 0.5rem;

    // main colors
    color: var(--text-color);
    background-color: var(--background-color);
}

.modal-close {
    // position: absolute;
    // right: 0.5rem;
    // top: 0.5rem;
    cursor: pointer;
    align-self: flex-end;
}
