// Styles for Landing Page Layout
.landing {
    position: relative;
    font-size: 3rem;
    margin: 0 0.5rem;
}

.theme-toggle {
    position: fixed;
    cursor: pointer;
    top: 1rem;
    right: 1rem;
    
    i {
        color: var(--accent-color);
        background-color: var(--background-color);
        border: 1px solid var(--accent-color);
        padding: 0.5em 0.5em;
        border-radius: 9999px;
    }

    i:hover {
        color: var(--background-color);
        box-shadow: inset 0 0 999px 999px var(--accent-color);
    }
}


.name-title {
    font-size: 4em;
}

.instructions {
    font-size: 1rem;
}

.mobile-instructions {
    font-size: 1rem;
    display: none;
}

.landing-nav {
    display: flex;
    gap: 1rem;
}

.landing-link {
    margin-top: 1rem;
    color: var(--background-color);
    background-color: var(--accent-color);
    padding: 0.3em 0.5em;
    border-radius: 1rem;
}

.landing-link:hover {
    box-shadow: inset 0 0 999px 999px rgba(255, 255, 255, 0.5);
}