@use '../abstracts';

:root {
    /* Font */
    font-size: abstracts.$base-font;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;

    /* color vars */
    /* main theme (dark theme) */
    --background-color: #{abstracts.$dark-background-color};
    --text-color: #{abstracts.$dark-text-color};
    --accent-color: #{abstracts.$dark-accent-color};
    --nav-active: rgba(255,255,255,0.2);
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

i {
    text-decoration: none;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    margin: 0;
}

h1 {
    font-size: clamp(4rem, 12vw + 1rem , 7rem);
}

h2 {
    font-size: clamp(2.5rem, 6vw + 1rem, 4.5rem);
}

h3 {
    font-size: 1.5rem;
}

button {
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
}