@use './abstracts';

@use './base/normalize';

@use './components';

@use './layouts/landing';
@use './layouts/about';
@use './layouts/projects';
@use './layouts/contact';
@use './layouts/null-path';

body {
    position: absolute;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#root {
    height: 100vh;
}

.App {
    text-align: center;
    height: 100vh;
    display: flex;
    background-color: var(--background-color);

}

.App.light {
    /* light theme (light theme) */
    --background-color: #{abstracts.$light-background-color};
    --text-color: #{abstracts.$light-text-color};
    --accent-color: #{abstracts.$light-accent-color};

    // undecided
    --nav-active: rgba(0, 0, 0, 0.2);
    
}

/* ---------------------------------------------------------------------------------------------------- */
/* Main Content /////////////////////////////////////////////////////////////////////////////////////// */
/* ---------------------------------------------------------------------------------------------------- */
main {
    display: flex;
    flex-direction: column;
    /* margin-left: 1rem; */
    flex-grow: 8;
    overflow-y: scroll;
    color: var(--text-color);

    &.centered {
        justify-content: center;
        align-items: center;
    }

    &::-webkit-scrollbar {
        width: var(--bar-spacing);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--background-color);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--accent-color);
    }
}

// main::-webkit-scrollbar {
//     width: var(--bar-spacing);
// }

// main::-webkit-scrollbar-track {
//     background-color: var(--background-color);
// }

// main::-webkit-scrollbar-thumb {
//     background-color: var(--accent-color);
//     /* border-radius: .5rem; */
// }

// main.centered {
//     justify-content: center;
//     align-items: center;
// }

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.linkedin {
    color: blue
}

.gmail {
    color: red;
}

.github {
    background-color: black;
    color: var(--text-color);
}

.gitlab {
    background-color: orange;
    color: var(--background-color);
}

.debug {
    border: 5px solid black;
}

/* Prefers animation off */
@media (prefers-reduced-motion: reduce) {
    *, 
    ::before, 
    ::after {
        animation-delay: -1ms !important;
        animation-duration: 1ms !important;
        animation-iteration-count: 1 !important;
        background-attachment: initial !important;
        scroll-behavior: auto !important;
        transition-duration: 0s !important;
        transition-delay: 0s !important;
    }
}