// Styles for Project Gallery Layout

.projects {
    margin: 0;
    gap: 1rem;
}

.filter-bar {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0 12vw;
}

#filter {
    height: 100%;
    background-color: var(--text-color);
    color: var(--background-color);
    border: 1px solid var(--text-color);
    border-radius: 999px 0 0 999px;
    padding: 0.1rem 0;
}

.direction-toggle {
    height: 100%;
    background-color: var(--text-color);
    color: var(--background-color);
    border: 1px solid var(--text-color);
    border-radius: 0 999px 999px 0rem;
    box-sizing: border-box;
    padding: 0.1rem 1rem;
}

.direction-toggle:hover {
    box-shadow: inset 0 0 999px 999px var(--nav-active);
    border: 1px solid var(--text-color);
}

.direction-toggle.descending {
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
}

.card-list {
    margin: 0 10vw;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
}

.project-card {
    display: flex;
    width: 15rem;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 15rem;
    justify-content: center;
    margin-bottom: 10px;
    text-align: left;

    z-index: 1;
    cursor: pointer;
}

.project-card-content {
    display: grid;
    grid-template-areas: "content";
    color: var(--text-color);
    background-color: var(--background-color);
    border: 3px solid var(--text-color);
    border-radius: 5px;

    transition: transform 1s, border-color 1.5s, box-shadow 1.5s;
    transform-style: preserve-3d;
}

.project-card-front,
.project-card-back {
    grid-area: content;
    backface-visibility: hidden;
    box-shadow: 0 5px 25px 0 var(--text-color);

}

.project-card-back {
    transform: rotateY(0.5turn);
    display: flex;
    flex-direction: column;
}

.card-techs {
    margin-top: auto;
    margin-bottom: 1rem;
}

.project-card:hover .project-card-content,
.project-card:focus .project-card-content {
    border-color: var(--accent-color);
    box-shadow: 0 5px 25px 0 var(--accent-color);

    transform: rotateY(0.5turn);
}

.card-img {
    flex: 0 0 auto;
    display: block;
    max-width: 15rem;
    height: 10rem;
    padding: 0;
}

.card-detail {
    width: 85%;
    margin: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-overflow: clip;
}

.card-detail * {
    margin: 0;
}

.tech-list {
    font-size: 0.7rem;
}

/* Mobile */
@media only screen and (max-width: 769px) {
    .card-list {
        margin: 0 10vw;
        margin-bottom: 10vh;
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }

    .project-card:focus .project-card-content {
        transform: none;
    }
}