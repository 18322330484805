@use '../abstracts';

/* Desktop Breakpoint */
@media only screen and (min-width: 769px) {
    .sidebar {
        position: fixed;
        display: flex;
        left: 0;
        height: 100%;
        z-index: 5;
        color: var(--background-color);
        transform: translateX(calc((abstracts.$nav-width - abstracts.$bar-spacing) * -1));
        transition: transform 400ms;

        &.active {
            width: auto;
            transform: translateX(0);
        }
    }

    // .sidebar.active {
    //     width: auto;
    //     transform: translateX(0);
    // }

    .navigation {
        display: flex;
        width: abstracts.$nav-width;
        flex-direction: column;
        font-size: 2rem;
        background-color: var(--background-color);
        border-right: abstracts.$bar-spacing solid var(--accent-color);
    }

    
    .nav-title {
        margin-top: abstracts.$top-spacing;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-top: 1em;
        padding-bottom: 1em;
        background-color: var(--accent-color);
    }
    
    .nav-button {
        padding: 0.7rem 0;
        color: var(--text-color);

        &.active {
            color: var(--accent-color);
            box-shadow: inset 0 0 999px 999px var(--nav-active);
        }

        &:hover {
            box-shadow: inset 0 0 999px 999px rgba(255, 255, 255, 0.5);
        }
    }
    
    // .nav-button:hover {
    //     box-shadow: inset 0 0 999px 999px rgba(255, 255, 255, 0.5);
    // }
    
    // .nav-button.active {
    //     color: var(--accent-color);
    //     box-shadow: inset 0 0 999px 999px var(--nav-active);
    // }
    
    .nav-aside {
        margin-top: abstracts.$top-spacing;
    }
    
    .nav-tag {
        border: none;
        border-radius: 0 5px 5px 0;
        padding: 0 0.3rem;
        background-color: var(--accent-color);

        &:hover {
            box-shadow: inset 0 0 999px 999px rgba(255, 255, 255, 0.5);
        }
    }
    
    // .nav-tag:hover {
    //     box-shadow: inset 0 0 999px 999px rgba(255, 255, 255, 0.5);
    // }

    .theme {
        margin-top: auto;
        border: none;
        background-color: var(--background-color);
    }
}

/* Mobile Breakpoint (targets tablets and smaller) */
@media only screen and (max-width: 769px) {
    .sidebar {
        position: fixed;
        display: flex;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        color: var(--background-color);
        
    }

    .navigation {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        font-size: 1.5rem;
        padding-bottom: 5px;
        background-color: var(--background-color);
        border-top: abstracts.$bar-spacing solid var(--accent-color);
    }
    
    .nav-title {
        display: none;
    }
    
    .nav-button {
        color: var(--text-color);

        &.active {
            color: var(--accent-color);
            /* box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1); */
        }
    }
    
    // .nav-button.active {
    //     color: var(--accent-color);
    //     /* box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1); */
    // }
    
    .nav-aside {
        display: none;
    }

    .theme {
        margin-top: 0;
        border: none;
        background-color: var(--background-color);
    }
}